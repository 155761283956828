<template>
  <base-template rgb="255,255,255" use-bstyle>
    <div class="gcash-box clearfix">
      <div class="top-icon">
        <img src="~@/assets/images/public/top-bg-icon.png" alt="">
      </div>

      <div class="step-box">
        <div class="step-line" />
        <p class="pay-title">RD pawnshop {{ $t('userCenter.paymentMethod') }}</p>
        <div class="step-cont">
          <div class="blod-title">Find your nearest RD pawnshop</div>
          <img class="rd-pawnshop-icon" src="~@/assets/images/stayPay/paymethod/rd-pawnshop-icon.png" alt="">
          <p class="grey-remark mt60 mb24">Provide the clerk with the following information</p>

          <ul class="pay-info">
            <li class="pay-info-item visibility">
              <span class="title">Biller: SKYPAY-API</span>
              <span class="cont">SKYPAY-API</span>
            </li>
            <li class="pay-info-item visibility">
              <span class="title">Customer Account No. (contractno.)</span>
              <span class="cont">1234567890</span>
            </li>
            <li class="pay-info-item">
              <span class="title">{{ $t('userCenter.payCode') }}</span>
              <span class="cont">{{ paymentCode }}</span>
            </li>
            <li class="pay-info-item visibility">
              <span class="title">Date</span>
              <span class="cont">1234567890</span>
            </li>
            <li class="pay-info-item visibility">
              <span class="title">Customer Name & Mobile No</span>
              <span class="cont">1234567890</span>
            </li>
          </ul>
          <p class="grey-remark">Please fill up the MLhuillier bills paymentform. You may pay in cash at any MLhuillierstores.</p>
          <img src="~@/assets/images/stayPay/paymethod/rd-pawnshop-flag-icon.png" alt="">
        </div>
        <p class="grey-remark mt60 last-remark">After the repayment is completed, the loan can be repeated. If you have any questions, you can contact customer service at any time.</p>
      </div>
    </div>
  </base-template>
</template>

<script>

export default {
  data() {
    return {
      paymentCode: localStorage.getItem('paymentCode')
    }
  }
}
</script>
<style lang="scss" scoped>
.gcash-box{
  position: relative;
  .top-icon{
    text-align: right;
    img{
      width: 428px;
      margin-right: -30px;
    }
  }
  .pay-title{
    height: 100px;
    line-height: 100px;
    font-size: 32px;
    font-weight: 500;
    color: #005FE9;
    width: 100%;
    background: url('~@/assets/images/stayPay/pay-type-tbj-icon.png') no-repeat center center;
    background-size: 100%;
    text-align: center;
    margin-bottom: 32px;
  }
  .step-box{
    background: #fff;
    padding-bottom: 20px;
    border-radius: 32px;
    margin-top: -92px;
    z-index: 2;
    position: relative;
    padding-bottom: 40px;
    .step-cont{
      padding-left: 136px;
      padding-right: 40px;
    }
    .step-line{
      position: absolute;
      width: 80px;
      height: 1130px;
      top: 140px;
      left: 40px;
      z-index: 3;
      background: url('~@/assets/images/stayPay/paymethod/rd-pawnshop-step-icon.png') no-repeat center center;
      background-size: 100%;
    }
    .remark{
      font-size: 24px;
      color: #000000;
      line-height: 36px;
      margin-bottom: 20px;
      padding: 0 20px;
      span{
        display: block;
      }
    }
    .next-icon{
      height: 80px;
      margin-bottom: 12px;
      background: url('~@/assets/images/stayPay/paymethod/ussc-next-icon.png') no-repeat center center;
      background-size: 80px;
    }
    img{
      width: 100%;
    }
  }
}

:deep(.child-title){
  display: none;
}
:deep(.headerbase){
  background: rgba(0, 95, 233, 0);
}
:deep(.is-content){
  background: #005FE9FF;
  border-radius: 0;
  margin-top: -88px;
  img{
    width: 100%;
  }
}
:deep(.headerbase .headerpulic-left){
  background: url('~@/assets/images/public/head-back-icon.png') no-repeat center center;
  background-size: 100%;
}
.last-remark{
  padding: 0 40px;
}
.blod-title{
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  line-height: 40px;
  margin-bottom: 10px;
  padding-left: 16px;
  padding-top: 28px;
}
.grey-remark{
  font-size: 28px;
  color: #CCCCCC;
  line-height: 40px;
}
.mt60{
  margin-top: 65px;
}
.mb24{
  margin-bottom: 24px;
}
.pay-info{
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 80px;
  .pay-info-item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    span{
      display: flex;
      align-items: center;
    }
    .title{
      flex:1;
      line-height: 40px;
    }
    .cont{
      color: #005FE9;
      text-align: right;
      flex-flow: row-reverse;
    }
  }
}
</style>
